<script>
import { SuawMainContent, SuawTabs, SuawForm, SuawButton, SuawTextInput, SuawLink } from "@suaw/suaw-component-library";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import { required, email, maxLength } from "vuelidate/lib/validators";
import * as UserApi from "../../api.js";

export default {
  name: "SignIn",
  components: { SuawMainContent, SuawTabs, SuawForm, SuawButton, SuawTextInput, SuawLink },
  data() {
    return {
      activeTabIndex: 0,
      loginForm: {
        email: "",
        password: ""
      },
      signupForm: {
        email: "",
        firstName: "",
        lastName: ""
      }
    };
  },
  validations: {
    loginForm: {
      email: { required, email },
      password: { required }
    },
    signupForm: {
      email: { required, email },
      firstName: { required, maxLength: maxLength(40) },
      lastName: { required, maxLength: maxLength(40) }
    }
  },
  created() {
    // Check for the "tab" query string and set the tab accordingly
    const tab = this.$route.query.tab;
    if (tab === "signup") {
      this.activeTabIndex = 1;
    } else {
      this.activeTabIndex = 0; // Default to Login
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    async loginByPassword() {
      const result = await UserApi.loginByPassword(this.loginForm.email, this.loginForm.password);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async registerUser() {
      const result = await UserApi.registerUser(this.signupForm.firstName, this.signupForm.lastName, this.signupForm.email);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onLogin() {
      this.$v.loginForm.$touch();
      if (this.$v.loginForm.$invalid) {
        this.$root.$emit("universal-error-message", "Invalid login form");
      } else {
        const loginByPasswordResult = await this.loginByPassword();
        if (loginByPasswordResult.success) {
          const { accessToken, refreshToken, user } = loginByPasswordResult.result.value || {};
          if (this.$route.query.redirectUrl) {
            this.$auth.refreshAccess({ accessToken, refreshToken, user, redirectLocation: this.$route.query.redirectUrl });
          } else {
            this.$auth.refreshAccess({ accessToken, refreshToken, user, redirectLocation: { name: "DashboardEvents" } });
          }
        }
      }
    },
    async onSignup() {
      this.$v.signupForm.$touch();
      if (this.$v.signupForm.$invalid) {
        this.$root.$emit("universal-error-message", "Invalid sign up form");
      } else {
        const registerUserResult = await this.registerUser();
        if (registerUserResult.success) {
          this.$router.push({ name: "CompleteAccount" });
        }
      }
    }
  }
};
</script>

<template>
  <SuawMainContent size="small">
    <SuawTabs v-model="activeTabIndex" :tabs="[{ label: 'Log In' }, { label: 'Sign Up' }]" tabs-type="lines" tabs-style="border">
      <template #tab-0>
        <SuawForm @submit="onLogin">
          <template #form>
            <SuawTextInput
              id="email-login"
              v-model="loginForm.email"
              type="email"
              placeholder="Email Address"
              is-required
              no-label
              :state="formFieldState($v, 'loginForm', 'email')"
              :error-message="validateErrors($v.loginForm.email, 'Email')"
              @blur="$v.loginForm.email.$touch()"
            />
            <SuawTextInput
              id="password-login"
              v-model="loginForm.password"
              type="password"
              placeholder="Password"
              is-required
              no-label
              :state="formFieldState($v, 'loginForm', 'password')"
              :error-message="validateErrors($v.loginForm.password, 'Password')"
              @blur="$v.loginForm.password.$touch()"
            />
            <SuawButton size="large" type="primary" button-text="Log In" />
            <SuawLink to="/reset-password" alignment="center">Don’t remember your password?</SuawLink>
          </template>
        </SuawForm>
      </template>

      <template #tab-1>
        <SuawForm @submit="onSignup">
          <template #form>
            <SuawTextInput
              id="first-name-signup"
              v-model="signupForm.firstName"
              type="text"
              placeholder="First Name"
              is-required
              no-label
              :state="formFieldState($v, 'signupForm', 'firstName')"
              :error-message="validateErrors($v.signupForm.firstName, 'First name')"
              @blur="$v.signupForm.firstName.$touch()"
            />
            <SuawTextInput
              id="last-name-signup"
              v-model="signupForm.lastName"
              type="text"
              placeholder="Last Name"
              is-required
              no-label
              :state="formFieldState($v, 'signupForm', 'lastName')"
              :error-message="validateErrors($v.signupForm.lastName, 'Last name')"
              @blur="$v.signupForm.lastName.$touch()"
            />
            <SuawTextInput
              id="email-signup"
              v-model="signupForm.email"
              type="email"
              placeholder="Email Address"
              is-required
              no-label
              :state="formFieldState($v, 'signupForm', 'email')"
              :error-message="validateErrors($v.signupForm.email, 'Email')"
              @blur="$v.signupForm.email.$touch()"
            />
            <SuawButton size="large" type="primary" button-text="Sign Up" />
            <SuawLink to="/" alignment="center">Having problems? Contact us.</SuawLink>
          </template>
        </SuawForm>
      </template>
    </SuawTabs>
  </SuawMainContent>
</template>
